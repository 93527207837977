<template>
  <div class="menu-area fixed-menu">
    <div class="closes" v-on:click="toggleSidebar('mob')">
      <i class="fa fa-times" aria-hidden="true"></i>
    </div>

    <!-- logo -->
    <div class="logo">
      <a
        @click.prevent="redirectIt('Dashboard')"
        href="/dashboard"
        title="Dashboard"
        data-toggle="tooltip"
      >
        <img
          class="img-fulid small-logo"
          src="../assets/img/logo-g.svg"
          alt="logo"
        />
        <img
          class="img-fulid big-logo"
          src="../assets/img/logo.svg"
          alt="logo"
        />
      </a>
    </div>
    <!-- logo -->

    <div
      class="modal fade"
      id="attention"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Attention</h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>

          <div class="modal-body">
            <p style="margin-bottom: 20px">
              Please Contact
              <span style="font-weight: 700; color: #3151a1">DealersGear</span>
              To Activate This Feature
            </p>
            <p style="margin-bottom: 5px">
              <span style="font-weight: 700; color: black">Email : </span
              ><a style="color: #3151a1" href="mailto:support@dealersgear.com"
                >support@dealersgear.com</a
              >
            </p>
            <p>
              <span style="font-weight: 700; color: black">Phone : </span>
              <span style="color: #3151a1">949-923-0190</span>
            </p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              class="rem-btn close"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
export default {
  name: "TheSidebar",
  data() {
    return {
      teams: [],
    };
  },
  created() {
    if (this.$storage.get("auth").user.user_type != 0) {
      this.getTeams();
    }

    this.$root.$on("action", function (action) {
      if (action == "openattentionmodal") {
        $("#attention").modal("show");
        $("#attention").appendTo("body");
      }
    });
  },
  methods: {
    checkPermissions(type) {
      if (
        type == 2 &&
        this.$storage.get("auth").user.dealer_permissions.includes("2") == false
      ) {
        $("#attention").modal("show");
        $("#attention").appendTo("body");
      }
      if (
        type == 3 &&
        this.$storage.get("auth").user.dealer_permissions.includes("3") == false
      ) {
        $("#attention").modal("show");
        $("#attention").appendTo("body");
      }
      if (
        type == 1 &&
        this.$storage.get("auth").user.dealer_permissions.includes("1") == false
      ) {
        $("#attention").modal("show");
        $("#attention").appendTo("body");
      }
    },
    openTeamModal() {
      this.$root.$emit("action", "openteamodal");
    },
    redirectIt(route, key = "", value = "") {
      if (this.$route.name == route) {
        if (key != "") {
          if (value == this.$route.params.cate) {
            this.$router.go();
          } else {
            this.$router.push({ name: route, params: { cate: value } });
          }
        } else {
          this.$router.go();
        }
      } else {
        if (key != "") {
          this.$router.push({ name: route, params: { cate: value } });
        } else {
          this.$router.push({ name: route });
        }
      }
    },
    toggleSidebar(where) {
      if (where == "web") {
        $(".menu-area").toggleClass("menu-full");
        $(".hide-item").toggleClass("show-item");
        $(".big-logo").toggleClass("show-logo");
        $(".audits-completed").toggleClass("menu-full-body");
      } else if (where == "mob") {
        $(".menu-area").toggleClass("mobile");
        $(".audits-completed").removeClass("menu-full-body");
        $(".menu-area").removeClass("menu-full");
        $(".hide-item").removeClass("show-item");
        $(".big-logo").removeClass("show-logo");
      }
    },
    getTeams() {
      axios
        .get("teams", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
            "X-Requested-With": "XMLHttpRequest",
          },
        })
        .then((res) => {
          const {
            data: { data },
          } = res;
          this.teams = data;
          this.$root.$on("newTeam", function (newTeam_data) {
            data.push(newTeam_data);
          });
          //console.log(res)
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    $(".closes").click(function () {
      $(".menu-area").removeClass("mobile");

      return false;
    });

    $(".menu-area ul li").click(function () {
      $(this).find(".sub-menu").toggleClass("submenu");
    });
  },
};
</script>
